import {useState} from 'react';
import PropTypes from 'prop-types';
import {IcCloseSm} from '../Atoms/Icons';

/**
 * Creates a Header Strip to display campaign contents
 */
export default function HeaderStripCampaign({
  className,
  classNames,
  useLeftImage,
  useRightImage,
  leftImage,
  rightImage,
  children,
  canBeClosed,
}) {
  const [isActive, setIsActive] = useState(true);

  if (!isActive) return null;

  return (
    <header
      className={`relative flex w-full items-center justify-center py-2.5 ${
        canBeClosed ? 'pl-3 pr-[50px] sm:pl-[50px]' : ''
      } ${className}`}
    >
      {useLeftImage && (
        <img
          className={`absolute left-0 top-0 h-full w-1/5 object-right ${classNames.image}`}
          src={leftImage}
          alt="Left side"
        />
      )}

      {useRightImage && (
        <img
          className={`absolute right-0 top-0 h-full w-1/5 object-left ${classNames.image}`}
          src={rightImage}
          alt="Right side"
        />
      )}

      <div className="z-0 flex h-full w-full items-center justify-center">{children}</div>

      {canBeClosed && (
        <button
          type="button"
          className={`absolute right-0 mr-2.5 flex h-6 w-6 items-center justify-center rounded-full bg-neutral-700 opacity-90 lg:h-7 lg:w-7 ${classNames.closeButton}`}
          onClick={() => setIsActive(false)}
          aria-label="Close headerstrip banner"
        >
          <IcCloseSm className="h-4 w-4 fill-white lg:h-5 lg:w-5" />
        </button>
      )}
    </header>
  );
}

HeaderStripCampaign.propTypes = {
  /**
   *  Optional CSS classes to be applied to the Header Strip Campaign component
   */
  className: PropTypes.string,
  /**
   *  Optional CSS classes to be applied to the images
   */
  classNames: PropTypes.shape({
    image: PropTypes.string,
    closeButton: PropTypes.string,
  }),
  /**
   *  The Left image source
   */
  leftImage: PropTypes.string,
  /**
   *  The Right image source
   */
  rightImage: PropTypes.string,
  /**
   *  Defines if the Left image is going to be used
   */
  useLeftImage: PropTypes.bool,
  /**
   *  Defines if the Right image is going to be used
   */
  useRightImage: PropTypes.bool,
  /**
   *  Child nodes passed into the HeaderStripCampaign component as children, ie: <Text />
   */
  children: PropTypes.node.isRequired,
  /**
   *  Defines if the Headerstrip has the "X" button to close
   */
  canBeClosed: PropTypes.bool,
};

HeaderStripCampaign.defaultProps = {
  className: '',
  classNames: {
    image: '',
    closeButton: '',
  },
  leftImage: '',
  rightImage: '',
  useLeftImage: false,
  useRightImage: false,
  canBeClosed: false,
};

export const PlasmicData = {
  name: 'HeaderStripCampaign',
  props: {
    className: 'string',
    useLeftImage: 'boolean',
    leftImage: 'imageUrl',
    useRightImage: 'boolean',
    rightImage: 'imageUrl',
    canBeClosed: 'boolean',
    children: {
      type: 'slot',
      defaultValue: [
        {
          type: 'text',
          value: 'Header Contents',
        },
      ],
    },
  },
  importPath: './components/UI/Molecules/HeaderStripCampaign',
  isDefaultExport: true,
};
